import { ViewBasic } from '@quatrecentquatre/manage-me';
export class HeaderView extends ViewBasic {
	constructor(options) {
		super(options);
	}

	initialize(){
        this.bindAll(['changeSlide']);
        this.addEvents();
    }

	addEvents(){
        window.addEventListener('form.change_slide', (event) => this.changeSlide(event.detail));
	}

    changeSlide(params) {
        let currentSlide = document.querySelector('.slides-ctn .slide.active');
        let currentIndex = currentSlide ? Array.from(currentSlide.parentNode.children).indexOf(currentSlide) : -1;

        let targetIndex;
        if (params && !isNaN(params.headerSlideIndex)) {
            targetIndex = params.headerSlideIndex;
        } else {
            targetIndex = (currentIndex < document.querySelectorAll('.slides-ctn .slide').length - 1) ? currentIndex + 1 : 0;
        }

        if (currentSlide) {
            currentSlide.classList.remove('active');
        }

        let targetSlide = document.querySelectorAll('.slides-ctn .slide')[targetIndex];
        if (targetSlide) {
            targetSlide.classList.add('active');
        }
    }

	removeEvents(){
        window.removeEventListener('form.change_slide', (event) => this.changeSlide(event.detail));
    }
}

Me.views['HeaderView'] = HeaderView;
