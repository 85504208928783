window.SETTINGS = {
    BASE_URL: window.location.protocol + "//" + window.location.host + "/",
    CURRENT_URL: window.location.protocol + "//" + window.location.host + window.location.pathname,
    VERSIONNING: new Date().getTime(),
    BREAKPOINT: null,
    BREAKPOINTS: {}
};

window.SETTINGS.BREAKPOINTS.LG_MIN = 1440;
window.SETTINGS.BREAKPOINTS.MD_MAX = SETTINGS.BREAKPOINTS.LG_MIN - 1;
window.SETTINGS.BREAKPOINTS.MD_MIN = 1024;
window.SETTINGS.BREAKPOINTS.SM_MAX = SETTINGS.BREAKPOINTS.MD_MIN - 1;
window.SETTINGS.BREAKPOINTS.SM_MIN = 768;
window.SETTINGS.BREAKPOINTS.XS_MAX = SETTINGS.BREAKPOINTS.SM_MIN - 1;
