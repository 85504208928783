import { BaseSlideView } from './base-slide';

export class UserFormView extends BaseSlideView {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['fileChanged','checkboxChanged', 'validateInput']);
        super.initialize();
    }

    validateInput(e) {
        const originalLength = e.target.value.length;
        e.target.value = e.target.value.replace(/[^0-9]/g, '');

        if (originalLength !== e.target.value.length) {
            const endOfInput = e.target.value.length;
            e.target.setSelectionRange(endOfInput, endOfInput);
        }

        if (e.target.value.length > 2) {
            e.target.value = e.target.value.slice(0, 2);
        }
    }
    addEvents() {
        super.addEvents();
        let number = this.el.querySelector('input[name=age]')

        number.addEventListener('input', e => this.validateInput(e))

        let fileInput = this.el.querySelector('input[type=file]');
        if (fileInput) {
            fileInput.addEventListener('change', this.fileChanged);
        }

        let clinicNameInput = this.el.querySelector('input[name="clinic-name"]');
        if (clinicNameInput) {
            $(clinicNameInput).autocomplete({
                minLength: 1,
                source: function (request, response) {
                    let results = $.ui.autocomplete.filter(newClinics, request.term);
                    response(results.slice(0, 15));
                },
                focus: function (event, ui) {
                    clinicNameInput.value = ui.item.label;
                    clinicNameInput.setAttribute('data-num', ui.item.$id);
                    return false;
                },
                select: function (event, ui) {
                    clinicNameInput.value = ui.item.label;
                    clinicNameInput.setAttribute('data-num', ui.item.$id);
                    return false;
                }
            }).data("ui-autocomplete")._renderItem = function (ul, item) {
                return $("<li>").append("<a>" + item.label + "</a>").appendTo(ul);
            };
        }
    }

    fileChanged(e) {
        let fullPath = e.currentTarget.value;
        if (fullPath) {
            let startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
            let filename = fullPath.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                filename = filename.substring(1);
            }
            e.currentTarget.parentElement.parentElement.querySelector('.file-name').innerHTML = filename;
        }
    }

    removeEvents() {
        super.removeEvents();

        let fileInput = this.el.querySelector('input[type=file]');
        if (fileInput) {
            fileInput.removeEventListener('change', this.fileChanged);
        }
    }
}

Me.views['UserFormView'] = UserFormView;







