import { ViewBasic } from '@quatrecentquatre/manage-me';

export class BaseSlideView extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['answerClickHandler','toggleRadioButtonHover','arrowClickHandler']);
        this.addEvents();
    }

    addEvents() {
        let self = this;
        let answerLabels = this.el.querySelectorAll('label.answer, .mdc-radio input');
        answerLabels.forEach(label => {
            label.addEventListener('click', self.answerClickHandler);
        });

        let answerLabelHover = this.el.querySelectorAll('label.answer');
        answerLabelHover.forEach(label => {
            label.addEventListener('mouseover', self.toggleRadioButtonHover);
            label.addEventListener('mouseleave', self.toggleRadioButtonHover);
        });

        let arrowButtons = this.el.querySelectorAll('button.arrow');
        arrowButtons.forEach(button => {
            button.addEventListener('click', self.arrowClickHandler);
        });

        let doseSelectLabels = this.el.querySelectorAll('label.answer.doseSelect');
        doseSelectLabels.forEach(label => {
            label.addEventListener('click', self.arrowClickHandler);
        });
    }

    answerClickHandler(e) {
        let formSlide = e.currentTarget.closest('.form-slide');

        let answerInformation = formSlide.querySelector('.answer-information');
        if (answerInformation) {
            answerInformation.classList.add('visible');
        }

        let arrow = this.el.querySelector('.arrow.next');
        if(arrow){
            arrow.removeAttribute('disabled');
        }
    }

    toggleRadioButtonHover(e) {
        let radioBtn = null;
        for (let sibling of e.currentTarget.parentNode.children) {
            if (sibling.classList.contains('mdc-radio')){
               radioBtn = sibling;
            }
        }

        if (radioBtn) {
            if (radioBtn.classList.contains('focused')) {
                if (radioBtn.classList.contains('hovered')) {
                    radioBtn.classList.remove('hovered');
                }
                return;
            }

            radioBtn.classList.toggle('hovered');
        }
    }

    arrowClickHandler(e) {
        e.preventDefault();
        // @NOTE - Force checked state on click of label for dosage radio buttons
        if (e.currentTarget.classList.contains('doseSelect')) {
            e.currentTarget.parentElement.querySelector('input[name="dosage"]').checked = true;
        }
        const eventName = e.currentTarget.classList.contains('next') ? 'form.next_slide' : 'form.prev_slide';
        window.dispatchEvent(new CustomEvent(eventName));
    }

    removeEvents() {
        let self = this;
        let answerLabels = this.el.querySelectorAll('label.answer, .mdc-radio input');
        answerLabels.forEach(label => {
            label.removeEventListener('click', self.answerClickHandler);
        });

        let answerLabelHover = this.el.querySelectorAll('label.answer');
        answerLabelHover.forEach(label => {
            label.removeEventListener('mouseover', self.toggleRadioButtonHover);
            label.removeEventListener('mouseleave', self.toggleRadioButtonHover);
        });

        let arrowButtons = this.el.querySelectorAll('button.arrow');
        arrowButtons.forEach(button => {
            button.removeEventListener('click', self.arrowClickHandler);
        });

        let doseSelectLabels = this.el.querySelectorAll('label.answer.doseSelect');
        doseSelectLabels.forEach(label => {
            label.removeEventListener('click', self.arrowClickHandler);
        });
    }
}

Me.views['BaseSlideView'] = BaseSlideView;
