import '../scss/app.scss';

import $ from 'jquery';
import jQuery from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';

window.$ = $;
window.jQuery = jQuery;

import './app.config';

//MAIN SCRIPTS
import './main'

function importAll (r) {
    r.keys().forEach(r);
}

//VIEWS
// automatic import
importAll(require.context('./views/', true, /\.js$/));

// or listed manually
// import './views/exemple';

// Only used in dev
if (module.hot) {
    module.hot.accept();
}
