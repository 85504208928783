import { ViewBasic } from '@quatrecentquatre/manage-me';

class Main extends ViewBasic {

    constructor(options) {
        super(options);

        this.initialize();
    }

    initialize() {
        this.bindAll(['breakpointHandler']);

        //list of breakpoints to test for dispatch
        this.breakpoints = [
            SETTINGS.BREAKPOINTS.SM_MIN,
            SETTINGS.BREAKPOINTS.MD_MIN,
            SETTINGS.BREAKPOINTS.LG_MIN
        ];
    }

    addEvents() {
        window.addEventListener('resize', this.breakpointHandler);

        $('body').on('click', 'a', function (e) {
            if ($(this).attr('href') == " " || $(this).attr('href') == "" || $(this).attr('href') == "#") {
                e.preventDefault();
            }
        });

        // Add ripple effect to all MDC radio buttons
        document.querySelectorAll('.mdc-form-field').forEach((formFieldElem) => {
            let formField = new mdc.formField.MDCFormField(formFieldElem);
            let radio = new mdc.radio.MDCRadio(formFieldElem.querySelector('.mdc-radio'));
            formField.input = radio;
        });
    }


    breakpointHandler() {
        var breakpoint;
        var viewPortSize = window.innerWidth;

        if (viewPortSize < this.breakpoints[0]) {
            breakpoint = String(this.breakpoints[0] - 1) + "-";
        }

        for (var i in this.breakpoints) {
            if (this.breakpoints[Number(i) + 1] != undefined && viewPortSize >= this.breakpoints[i] && viewPortSize < this.breakpoints[Number(i) + 1]) {
                breakpoint = String(this.breakpoints[i]) + "-" + String(this.breakpoints[Number(i) + 1] - 1);
            }
            if (viewPortSize >= this.breakpoints[this.breakpoints.length - 1]) {
                breakpoint = String(this.breakpoints[i]) + "+";
            }
        }

        if (SETTINGS.BREAKPOINT != breakpoint) {
            SETTINGS.BREAKPOINT = breakpoint;
            Me.dispatch.emit('breakpointChanged', this, {breakpoint: breakpoint});
        }
    }
}
document.addEventListener('DOMContentLoaded', function () {
    new Main({});
});

Me.views['Main'] = Main;

