import { BaseSlideView } from './base-slide';

export class DinView extends BaseSlideView {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['validateDinCode','doTracking']);
        this.dinCodesTrio = [
            '404',
            '02492652',
            '02492687',
            '02492660',
            '02492695',
            '02492679',
            '02492709'
        ];

        this.dinCodes = [
            '405',
            '02452014',
            '02452022',
            '02452030',
            '02452049',
            '02452057',
            '02452065'
        ]
        this.addEvents();

        // Tracks the slide
        this.doTracking();
    }

    addEvents() {
        super.addEvents();
        let button = document.querySelector('button');
        if (button) {
            button.addEventListener('click', this.validateDinCode);
        }
    }

    validateDinCode(e) {
        e.preventDefault();
        let inputDinValue = document.querySelector('#input-din').value;

        if (this.dinCodesTrio.indexOf(inputDinValue) !== -1) {
            // Hide all other logos
            this.toggleElements(['Simparica', 'SimparicaBase', 'SimparicaLogo'], 'none');

            // Hide Simparica Answers
            let sectionsToHide = document.querySelectorAll('.simparica-answers')
            for (let i = 0; i < sectionsToHide.length; ++i) {
                sectionsToHide[i].style.display = 'none';
            }

            this.scrollTo('.simparica-logos');
            window.dispatchEvent(new CustomEvent('form.din_valid'));
        } else if (this.dinCodes.indexOf(inputDinValue) !== -1) {
            // Hide all logos
            this.toggleElements(['SimparicaTrio', 'SimparicaTrioBase', 'SimparicaTrioLogo'], 'none');

            // Hide Simparica Trio Answers
            let sectionsToHide = document.querySelectorAll('.simparica-trio-answers')
            for (let i = 0; i < sectionsToHide.length; ++i) {
                sectionsToHide[i].style.display = 'none';
            }

            // Show Simparica Logo
            document.getElementById('SimparicaLogo').style.display = 'block';

            this.scrollTo('.simparica-logos');
            window.dispatchEvent(new CustomEvent('form.din_valid'));
        } else {
            this.toggleElement('.error-msg', 'add', 'display');
        }
    }

    toggleElements(ids, displayValue) {
        ids.forEach(id => {
            const element = document.getElementById(id);
            if (element) {
                element.style.display = displayValue;
            }
        });
    }

    toggleElement(selector, method, className) {
        const element = document.querySelector(selector);
        if (element) {
            element.classList[method](className);
        }
    }

    scrollTo(selector) {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    /**
     * Tracking for the din slide
     */
    doTracking() {
        dataLayer.push({
            'event': 'change_slide',
            'active_slide': this.el.id
        });
    }

    removeEvents() {
        super.removeEvents();
        let button = document.querySelector('button');
        if (button) {
            button.removeEventListener('click', this.validateDinCode);
        }
    }

}

Me.views['DinView'] = DinView;
