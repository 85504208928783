import { string, mixed } from 'yup';
import { FormBase } from "@quatrecentquatre/form-me";
export class FormView extends FormBase {
    constructor(options) {
        super(options);

        this.postalCodeRegExp = /^((\d{5}-?\d{4})|(\d{5})|([A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d))$/i;

        this.addFields([
            {
                name: 'first-name',
                validationSchema: string().required(),
                $error: document.querySelector('[name="first-name"]').parentElement.querySelector('.error'),
            },
            {
                name: 'last-name',
                validationSchema: string().required(),
                $error: document.querySelector('[name="last-name"]').parentElement.querySelector('.error'),
            },
            {
                name: 'email',
                validationSchema: string().email().required(),
                $error: document.querySelector('[name="email"]').parentElement.querySelector('.error'),
            },
            {
                name: 'address',
                validationSchema: string().required(),
                $error: document.querySelector('[name="address"]').parentElement.querySelector('.error'),
            },
            {
                name: 'city',
                validationSchema: string().required(),
                $error: document.querySelector('[name="city"]').parentElement.querySelector('.error'),
            },
            {
                name: 'province',
                validationSchema: string().required(),
                $error: document.querySelector('[name="province"]').parentElement.querySelector('.error'),
            },
            {
                name: 'postal-code',
                validationSchema: string().matches(this.postalCodeRegExp).required(),
                $error: document.querySelector('[name="postal-code"]').parentElement.querySelector('.error'),
            },
            {
                name: 'clinic-name',
                validationSchema: string().required(),
                $error: document.querySelector('[name="clinic-name"]').parentElement.querySelector('.error'),
            },
            {
                name: 'receipt-file',
                validationSchema: mixed()
                    .required()
                    .test(
                        "fileSize",
                        "sizeError",  // Set a unique error key
                        function (value) {
                            return !value || value.size <= 25000000; // 10MB limit;
                        }
                    )
                    .test(
                        "fileType",
                        "typeError",  // Set a unique error key
                        function (value) {

                            // Allowed files
                            const allowedExtensions = ['gif', 'pdf', 'bmp', 'jpg', 'jpeg', 'png', 'PNG', 'BMP', 'GIF', 'JPG', 'JPEG', 'PDF', 'doc', 'docx', 'DOC', 'DOCX'];

                            // Forces extension name to lowercase
                            const fileExtension = value?.name.split('.').pop().toLowerCase();
                            return allowedExtensions.includes(fileExtension);
                        }
                    ),
                $error: document.querySelector('[name="receipt-file"]').parentElement.parentElement.querySelector('.error'),
            },
        ])
    }

    addEvents() {
        let self = this;
        super.addEvents();
        window.addEventListener('form.din_valid', this.nextSlide.bind(this));
        window.addEventListener('form.next_slide', this.nextSlide.bind(this));
        window.addEventListener('form.prev_slide', this.prevSlide.bind(this));

        let checkboxes = document.querySelectorAll('input[type=checkbox]');
        checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', self.checkboxChanged);
        });
    }

    removeEvents() {
        let self = this;

        super.removeEvents();
        window.removeEventListener('form.din_valid', this.nextSlide.bind(this));
        window.removeEventListener('form.next_slide', this.nextSlide.bind(this));
        window.removeEventListener('form.prev_slide', this.prevSlide.bind(this));

        let checkboxes = document.querySelectorAll('input[type=checkbox]');
        checkboxes.forEach(checkbox => {
            checkbox.removeEventListener('change', self.checkboxChanged);
        });
    }

    ajaxSuccess(e) {
        super.ajaxSuccess(e);
        window.dispatchEvent(new CustomEvent('form.next_slide', this, { detail: { headerSlideIndex: 0 } }));
    }


    resetFieldValue(e) {
        //@NOTE 404 - FIX FOR FORM RESET
        e.type === "checkbox" || e.type === "radio" ? e.$el.setAttribute("checked", !1) : e.type === "select" ? e.defaultValue && (e.$el.value = e.defaultValue) : e.$el.value = ""; //, e.$el.trigger("change")
    }

    handleValidationSuccess(fields) {
        super.handleValidationSuccess(fields);

        // @NOTE 404 - Did not find any errors in files
        // if (this.$messages) {
        //     this.$messages.querySelector('.error').classList.add('hide');
        //     this.$messages.querySelector('.error-none').classList.remove('hide');
        // }

        // @NOTE 404 - Did not find any trackingMessages in files
        // if (this.tracker_name) {
        //     this.trackingMessages.onValidationSuccess.call(this);
        // }
    }

    checkboxChanged(e) {
        let wrapper = e.currentTarget.parentElement;
        wrapper.classList.toggle("active");
        wrapper.nextElementSibling.classList.toggle('hide');
    }

    nextSlide(params) {
        let currentSlide = document.querySelector('.form-slide.active');
        let currentIndex = currentSlide ? Array.from(currentSlide.parentNode.children).indexOf(currentSlide) : -1;

        let nextIndex = (currentIndex < document.querySelectorAll('.form-slide').length - 1) ? currentIndex + 1 : 0;

        if (currentSlide) {
            currentSlide.classList.remove('active');
        }

        const nextSlide = document.querySelectorAll('.form-slide')[nextIndex];
        if (nextSlide) {
            nextSlide.classList.add('active');
            this.doTracking();

            if (params.detail && !isNaN(params.detail.headerSlideIndex)) {
                window.dispatchEvent(new CustomEvent("form.change_slide", {detail: {headerSlideIndex: params.detail.headerSlideIndex}}));
            } else {
                window.dispatchEvent(new CustomEvent("form.change_slide"));
            }
        }
    }

    prevSlide() {
        let currentSlide = document.querySelector('.form-slide.active');
        let currentIndex = currentSlide ? Array.from(currentSlide.parentNode.children).indexOf(currentSlide) : -1;

        let prevIndex = (currentIndex > 0) ? currentIndex - 1 : document.querySelectorAll('.form-slide').length - 1;

        if (currentSlide) {
            currentSlide.classList.remove('active');
        }

        const prevSlide = document.querySelectorAll('.form-slide')[prevIndex];
        if (prevSlide) {
            prevSlide.classList.add('active');
            this.doTracking();
            window.dispatchEvent(new CustomEvent("form.change_slide"));
        }
    }

    /**
     * If the user changes the slide, it will track the now active slide accordingly
     */
    doTracking() {
        let activeSlideId = document.querySelector('.form-slide.active').id;

        dataLayer.push({
            'event': 'change_slide',
            'active_slide': activeSlideId
        });
    }


}

Me.forms['FormView'] = FormView;
